model-viewer{
    width:100%;
    height:100%;
}

.itemBox {
    border: 1px solid #ffffff1f;
    border-radius: 16px;
    background: white;
    box-shadow: 0px 0px 5px 0px rgba(153,59,225,1);
    margin-bottom: 35px;
    padding: 10px;
    margin-top: 150px;
}

.navbar {
    background-color: white;
    box-shadow: -1px 0px 12px 0px rgba(138,126,138,1);
    height: 110px;
    position: fixed;
    z-index: 1;
}
